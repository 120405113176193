<template>
  <div class="banner5">
    <img src="../../assets/banner/banner5.png" alt="" />
    <div id="mapsnative"></div>
    <div id="parking"></div>
    <div id="locationMap"></div>
    <div id="tap"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "banner5",
  data() {
    return {
      lottie: {},
      lottieList: [
        require("../../assets/lottie/maps-native.json"),
        require("../../assets/lottie/mapp-parking.json"),
        require("../../assets/lottie/location-map.json"),
        require("../../assets/lottie/tap-the-crosshair-v1.json"),
      ],
    };
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("mapsnative"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("parking"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("locationMap"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[2],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("tap"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[3],
    });
  },
};
</script>

<style lang="scss" scoped>
.banner5 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  #mapsnative {
    position: absolute;
    width: 27.3%;
    right: 20.6%;
    bottom: 25.5%;
  }
  #parking {
    position: absolute;
    width: 17%;
    right: 0;
    bottom: 20%;
  }
  #locationMap {
    position: absolute;
    width: 27.4%;
    left: 20.5%;
    bottom: 25.5%;
  }
  #tap {
    position: absolute;
    width: 16.5%;
    left: 0;
    bottom: 25.5%;
  }
}
</style>
