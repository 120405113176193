<template>
  <div class="banner2">
    <img src="../../assets/banner/banner2.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.banner2 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
}
</style>
