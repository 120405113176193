import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import swiper from "swiper";
import "swiper/css/swiper.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import Bus from "./util/bus";
import infiniteScroll from "vue-infinite-scroll";
import vcolorpicker from "vcolorpicker";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
//动画效果
import animated from "animate.css";

Vue.config.productionTip = false;
const Swiper = new swiper();
Vue.use(Antd);
Vue.use(Swiper);
Vue.use(animated);
Vue.use(infiniteScroll);
Vue.use(vcolorpicker);
Vue.prototype.$bus = Bus;

mapboxgl.accessToken =
  "pk.eyJ1IjoibHVvbWFuMTU5IiwiYSI6ImNrc2ZteHBzYTFjMmUydnBhamluNG9wM3MifQ.hBqmfzHdkR0yTycDHGGPzQ";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
