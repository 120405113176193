import router from "@/router/index";
import axios from "axios";
import Antd from "ant-design-vue";
// 创建axios 赋值给常量service
const service = axios.create({
  headers: {
    "Cache-Control": "no-cache",
  },
});
// 添加请求拦截器（Interceptors）
service.defaults.headers.post["Content-Type"] =
  "application/json;charset=UTF-8";
export const BASE_NAME = "/snail_map";
async function doRequest() {
  Antd.Message.error("登录会话已过期，请重新登录");
  sessionStorage.clear();
  return router.replace({
    path: "/login",
  });
}
export const ResponseCode = {
  success: true,
};
service.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("authorization");
    if (token) {
      // config.params = { token: token } 如果要求携带在参数中
      config.headers.Authorization = `Bearer ${token}`;
    }
    // 发送请求之前做写什么
    return config;
  },
  function (error) {
    // 请求错误的时候做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          return doRequest();
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 405:
          error.message = "请求方法不允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        error.message = "服务器响应超时";
      }
      error.message = "连接服务器失败";
    }
    // alert(error.message)
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default service;
