import http, { BASE_NAME, ResponseCode } from "./request.js";

export const apiPath_img_list = BASE_NAME;
export async function API_imgList(type) {
  try {
    const response = await http.get(`${apiPath_img_list}?fl=${type}&gs=json`);
    return response.data;
  } catch (e) {
    return [(typeof e.message === "string" && e.message) || "error", null];
  }
}
export const apiPath_qr_code = `${BASE_NAME}/app_ver/latest`;
export async function API_QrCode(type) {
  try {
    const response = await http.get(apiPath_qr_code);
    return [null, response.data.data];
  } catch (e) {
    return [(typeof e.message === "string" && e.message) || "error", null];
  }
}
