<template>
  <div class="home">
    <div class="home_main">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <Banner1 class="banner1"></Banner1>
          </div>
          <div class="swiper-slide">
            <Banner2 class="banner2"></Banner2>
          </div>
          <div class="swiper-slide">
            <Banner3 class="banner3"></Banner3>
          </div>
          <div class="swiper-slide">
            <Banner4 class="banner4"></Banner4>
          </div>
          <div class="swiper-slide">
            <Banner5 class="banner5"></Banner5>
          </div>
          <div class="swiper-slide">
            <Banner6 class="banner6"></Banner6>
          </div>
          <div class="swiper-slide">
            <Banner7 class="Banner7"></Banner7>
          </div>
          <div class="swiper-slide">
            <Banner8 class="Banner8"></Banner8>
          </div>
        </div>
      </div>
    </div>
    <div class="home_title">
      <h2
        style="
          font-size: 48px;
          font-family: Montserrat, Montserrat-SemiBold;
          font-weight: normal;
          color: #000000;
          line-height: 72px;
          letter-spacing: -0.07px;
        "
      >
        功能特点
      </h2>
      <div class="content">
        <div class="content_title">二三维沉浸式地图交互</div>
        <div class="content_text">
          手势操作开启三维地形效果，缩放，旋转一气呵成，带来流畅的交互体验。
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
      <div class="content">
        <div class="content_title">禁飞区查询</div>
        <div class="content_text">
          实时查询全国的禁飞区域（部分禁飞区由于特殊原因并未在地图上进行标记）
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
      <div class="content">
        <div class="content_title">自定义图源</div>
        <div class="content_text">
          支持标准协议的wms及wmts地图发布服务，用户可自定义在线地图类型
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
      <div class="content">
        <div class="content_title">坐标格式转换</div>
        <div class="content_text">
          常用的地理小工具，帮助用户快速的进行坐标数据的换算和保存
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
      <div class="content">
        <div class="content_title">AR方向指引</div>
        <div class="content_text">
          通过AR（增强现实）的方式，能够更加直观的进行方向的指引和标记
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
      <div class="content">
        <div class="content_title">轨迹回放分析</div>
        <div class="content_text">
          对轨迹的海拔情况、速度情况及里程进行分析和展示，3D回放能够全方位的进行轨迹采集过程中的实际情况
        </div>
        <a-button class="content_button">详细</a-button>
      </div>
    </div>
    <div class="body">
      <div class="body_content">
        <div id="maps"></div>
        <div class="body_name">
          <div class="body_title">出行圈分析</div>
          <div class="body_text">
            分析步行、骑行、驾驶多种出行方式的到达区域，给生活选址、户外健身提供参考信息
            13、坐标格式转换
          </div>
        </div>
      </div>
      <div class="body_content_right">
        <div class="body_color">
          <div class="body_name" style="align-items: flex-start">
            <div class="body_title">蜗牛地图</div>
            <div class="body_text">
              <div>为用户提供专业，全面，有趣的地图服务平台。</div>
              <div style="margin-top: 10px">1.影像地图与地形</div>
              <div style="margin-top: 10px">2.轨迹记录与回放</div>
              <div style="margin-top: 10px">3.数据查询与分析</div>
              <div style="margin-top: 10px">4.适用地理小工具</div>
            </div>
          </div>
          <div id="world"></div>
        </div>
      </div>
      <div class="body_content_botm">
        <div class="greenmap">
          <img src="@/assets/greenmap.jpg" />
        </div>
        <div class="body_name">
          <div class="body_title">标题名称</div>
          <div class="body_text">
            {{ msg }}
          </div>
          <div class="body_button">
            <a-button class="body_button_color">按钮</a-button>
            <a-button class="body_button_color">按钮</a-button>
          </div>
        </div>
      </div>
      <div class="promise">
        <div class="promise_title">产品特色</div>
        <div class="promise_text">
          作为我们优质服务的一部分，我们也想提供一些额外的服务。
        </div>
      </div>
      <div class="promise_contents">
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/Group.png" />
          <div class="promise_content">
            <div class="promise_content_title">地图/地形离线缓存</div>
            <div class="promise_content_text">
              户外用户可能随时出现无网络的情况，通过实
              时/计划缓存的方式将目标区域的地图提前准 备，达到离线使用的要求
            </div>
          </div>
        </div>
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/computer.png" />
          <div class="promise_content">
            <div class="promise_content_title">多屏数据对比</div>
            <div class="promise_content_text">
              分屏展示不同的数据源，能够直观的进行数
              据的比对，辅助用户进行相关的分析和判断
            </div>
          </div>
        </div>
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/team.png" />
          <div class="promise_content">
            <div class="promise_content_title">客制化服务</div>
            <div class="promise_content_text">
              支持功能应用的定制化服务，满足不同专业用 户的工作需求
            </div>
          </div>
        </div>
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/agile.png" />
          <div class="promise_content">
            <div class="promise_content_title">航飞安全助手</div>
            <div class="promise_content_text">
              根据设定好的航飞区域及飞行参数，自动分析 安全飞行高度
            </div>
          </div>
        </div>
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/lifelong.png" />
          <div class="promise_content">
            <div class="promise_content_title">插件式功能</div>
            <div class="promise_content_text">
              采用插件式功能设计，最大限度的定制私有 化功能界面
            </div>
          </div>
        </div>
        <div class="promise_contents_text">
          <img class="icon" src="@/assets/icon/secrecy.png" />
          <div class="promise_content">
            <div class="promise_content_title">地图壁纸</div>
            <div class="promise_content_text">
              城市肌理图色彩定制，形成独特的地图壁纸
            </div>
          </div>
        </div>
      </div>
      <div class="idea">
        <div class="idea_main">
          <div>
            <QrCode></QrCode>
          </div>
          <div class="idea_content">
            <div class="idea_title">扫描左侧二维码下载蜗牛地图APP</div>
            <div class="idea_text">
              版本号：{{ appDate && appDate.versionName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import Swiper from "swiper";
import "swiper/css/swiper.css";

import Banner1 from "@/components/banner/banner1.vue";
import Banner2 from "@/components/banner/banner2.vue";
import Banner3 from "@/components/banner/banner3.vue";
import Banner4 from "@/components/banner/banner4.vue";
import Banner5 from "@/components/banner/banner5.vue";
import Banner6 from "@/components/banner/banner6.vue";
import Banner7 from "@/components/banner/banner7.vue";
import Banner8 from "@/components/banner/banner8.vue";
import QrCode from "../components/qrCode.vue";
import { API_QrCode } from "../util/map-source";

export default {
  name: "Home",
  data() {
    return {
      msg: " 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容 请输入文字内容",
      itemlist: [
        {
          id: 0,
          src: require("../assets/banner/banner1.png"),
        },
        {
          id: 1,
          src: require("../assets/banner/banner2.png"),
        },
        {
          id: 2,
          src: require("../assets/banner/banner3.png"),
        },
        {
          id: 3,
          src: require("../assets/banner/banner4.png"),
        },
        {
          id: 4,
          src: require("../assets/banner/banner5.png"),
        },
        {
          id: 5,
          src: require("../assets/banner/banner6.png"),
        },
        {
          id: 6,
          src: require("../assets/banner/banner7.png"),
        },
      ],
      lottieList: [
        require("../assets/lottie/maps.json"),
        require("../assets/lottie/world-population-analysis.json"),
      ],
      swiper: null,
      appDate: {},
    };
  },
  components: {
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
    Banner8,
    QrCode,
  },
  mounted() {
    lottie.loadAnimation({
      container: document.getElementById("maps"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    lottie.loadAnimation({
      container: document.getElementById("world"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
    let mySwiper = new Swiper(".swiper", {
      //一张图
      slidesPerView: "auto",
      // 设置轮播
      // loop: true,
      //修改导航字体颜色
      on: {},
      //自动播放5s
      autoplay: {
        delay: 5000,
      },
    });
    this.appVersion();
  },
  methods: {
    async appVersion() {
      const [err, date] = await API_QrCode();
      if (!err) {
        this.appDate = date;
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .home_main {
    width: 100%;
    .swiper {
      width: 100%;
      min-width: 650px;
      overflow: hidden;
      .swiper-slide {
        display: flex;
        min-width: 650px;
      }
    }
  }
  .home_title {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    h2 {
      width: 100%;
      font-size: 48px;
      margin-bottom: 20px;
      font-family: Montserrat, Montserrat-Regular;
    }
    .content {
      width: calc(50% - (20px * 2));
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .content_title {
        font-size: 24px;
        font-family: Montserrat, Montserrat-Bold;
        font-weight: 700;
        color: #000000;
        line-height: 36px;
        letter-spacing: -0.04px;
      }
      .content_text {
        margin-top: 20px;
        font-size: 18px;
        font-family: Montserrat, Montserrat-Regular;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 30px;
        letter-spacing: -0.03px;
      }
      .content_button {
        cursor: pointer;
        margin-top: 20px;
        background: #fff;
        color: #ffac1c;
        width: 130px;
        height: 40px;
        border: 1px solid #ffac1c;
        border-radius: 6px;
      }
    }
  }
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    .body_content {
      display: flex;
      width: 80%;
      margin: 0 auto;
      justify-content: space-between;
      #maps {
        width: 50%;
        padding: 0 20px;
      }
      .body_name {
        width: 45%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .body_title {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 24px;
          font-family: Montserrat, Montserrat-Bold;
          color: #000000;
          line-height: 36px;
          letter-spacing: -0.04px;
        }
        .body_text {
          font-size: 18px;
          font-family: Montserrat, Montserrat-Regular;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 24px;
          letter-spacing: -0.03px;
        }
      }
    }
    @media screen and (max-width: 650px) {
      .body_content {
        width: 100%;
        flex-direction: column-reverse;
        align-items: center;
        margin: 20px;
        .body_name {
          margin-bottom: 20px;
          .body_title {
            margin-bottom: 20px;
          }
        }
      }
    }
    .body_content_right {
      width: 100%;
      margin-top: 50px;
      background: #f5f5f5;
      .body_color {
        width: 80%;
        margin: 0 auto;
        display: flex;
        padding: 30px 0;
        #world {
          width: 50%;
          padding: 0 20px;
          background: #0686e1;
        }
        .body_name {
          width: 45%;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .body_title {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 700;
          }
          .body_text {
            font-size: 18px;
            font-family: Montserrat, Montserrat-Regular;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
            line-height: 24px;
            letter-spacing: -0.03px;
          }
        }
      }
    }
    @media screen and (max-width: 650px) {
      .body_content_right {
        .body_color {
          width: 100%;
          flex-direction: column;
          align-items: center;
          margin: 20px;
          .body_name {
            margin-bottom: 20px;
            .body_title {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .body_content_botm {
      display: flex;
      width: 80%;
      margin: 50px auto;
      justify-content: space-between;
      background: #fff7e9;
      .greenmap {
        width: 50%;
        padding: 0 20px;
        background: #000;
        img {
          position: relative;
          left: 8%;
          width: 110%;
          height: 80%;
          border-radius: 10px;
          margin: 10% 0;
        }
      }
      .body_name {
        width: 45%;
        padding: 0 20px;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 70px 0;
        .body_title {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
          align-self: flex-start;
        }
        .body_button {
          margin-top: 30px;
          .body_button_color {
            cursor: pointer;
            width: 120px;
            height: 35px;
            background: #ffac1c;
            border: 1px solid #ffac1c;
            border-radius: 27px;
            margin: 10px;
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
    @media screen and (max-width: 650px) {
      .body_content_botm {
        flex-direction: column-reverse;
        align-items: center;
        .greenmap {
          width: 70%;
          height: 120%;
          padding: 20px;
        }
        .body_name {
          margin: 10px;
        }
      }
    }
    .promise {
      text-align: center;
      .promise_title {
        font-size: 36px;
        font-family: Poppins, Poppins-Bold;
        font-weight: 700;
        text-align: CENTER;
        color: #000d2f;
        line-height: 36px;
      }
      .promise_text {
        margin-top: 20px;
        font-size: 14px;
        font-family: Poppins, Poppins-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #000d2f;
        line-height: 14px;
      }
    }
    .promise_contents {
      width: 80%;
      margin: 0 auto;
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: repeat(3);
      .promise_contents_text {
        display: flex;
        background: rgba(255, 172, 28, 0.1);
        margin: 10px;
        padding: 50px;
        align-items: flex-start;
        border-radius: 10px;
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
        .promise_content_title {
          font-size: 22px;
          line-height: 24px;
          font-family: Poppins, Poppins-SemiBold;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .promise_content_text {
          font-size: 14px;
          font-family: Poppins, Poppins-Regular;
          font-weight: 400;
          line-height: 30px;
        }
      }
      @media screen and (max-width: 800px) {
        .promise_contents_text {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    @media screen and (max-width: 650px) {
      .promise_contents {
        grid-template-columns: repeat(1, 100%);
        .icon {
          margin-bottom: 20px;
        }
      }
    }
    .idea {
      width: 100%;
      height: 300px;
      margin-top: 20px;
      background: rgba(255, 172, 28, 0.09);

      .idea_main {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
        .idea_content {
          width: 60%;
          display: flex;
          flex-direction: column;
          .idea_title {
            color: #262626;
            font-size: 36px;
            font-family: Poppins, Poppins-Medium;
            font-weight: 500;
            line-height: 48px;
          }
          .idea_text {
            color: #c4c4c4;
            font-size: 18px;
            font-family: Poppins, Poppins-Regular;
            font-weight: 400;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
