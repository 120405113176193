import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Home",
    component: () => import("../views/index.vue"),
    children: [
      {
        path: "/Home",
        name: "Home",
        component: Home,
        meta: {
          title: "主页",
          index: 1,
        },
      },
      {
        path: "/Technology",
        name: "Technology",
        component: () => import("../views/Technology.vue"),
        meta: {
          title: "技术支持",
          index: 2,
        },
      },
      {
        path: "/Product",
        name: "Product",
        component: () => import("../views/Product.vue"),
        meta: {
          title: "产品中心",
          index: 3,
        },
      },
      {
        path: "/About",
        name: "About",
        component: () => import("../views/About.vue"),
        meta: {
          title: "关于我们",
          index: 4,
        },
      },
      {
        path: "/discoverMap",
        name: "discoverMap",
        component: () => import("../views/discoverMap.vue"),
        meta: {
          title: "发现地图",
          index: 5,
        },
      },
      {
        path: "/lightWorld",
        name: "lightWorld",
        component: () => import("../views/lightWorld.vue"),
        meta: {
          title: "光影世界",
          index: 6,
        },
      },
      {
        path: "/mapWallpaper",
        name: "mapWallpaper",
        component: () => import("../views/mapWallpaper.vue"),
        meta: {
          title: "地图壁纸",
          index: 7,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  //跳转回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

export default router;
