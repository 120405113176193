<template>
  <div>
    <div v-show="appDate" class="qrcode" ref="qrCodeUrl"></div>
    <div v-show="!appDate" style="text-align: center; margin: 10px 0">
      <h1>加载中<a-spin size="large" style="margin-left: 10px" /></h1>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { API_QrCode } from "../util/map-source";
export default {
  data() {
    return {
      appDate: "",
    };
  },
  mounted() {
    this.qrCode();
  },
  methods: {
    async qrCode() {
      const [err, date] = await API_QrCode();
      if (!err) {
        this.appDate = date;
        this.creatQrCode(date.url);
      }
    },
    creatQrCode(url) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 248,
        height: 248,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  background-color: #fff; //设置白色背景色
  padding: 6px; // 利用padding的特性，挤出白边
  box-sizing: border-box;
}
</style>
