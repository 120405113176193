<template>
  <div class="banner3">
    <img src="../../assets/banner/banner3.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.banner3 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
}
</style>
