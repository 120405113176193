<template>
  <div class="banner8">
    <img src="../../assets/banner/banner8.png" alt="" />
    <div id="mappin"></div>
    <div id="locpin"></div>
    <div id="ggicon"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "Banner8",
  data() {
    return {
      lottie: {},
      lottieList: [
        require("../../assets/lottie/location-map-pin.json"),
        require("../../assets/lottie/map-location-pin.json"),
        require("../../assets/lottie/google-icons-android-auto.json"),
      ],
    };
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("mappin"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("locpin"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("ggicon"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[2],
    });
  },
};
</script>

<style lang="scss" scoped>
.banner8 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  #mappin {
    position: absolute;
    width: 8%;
    left: 31.5%;
    top: 25%;
  }
  #locpin {
    position: absolute;
    width: 15%;
    left: 13%;
    top: 47%;
  }
  #ggicon {
    position: absolute;
    width: 8%;
    right: 44%;
    bottom: 20%;
    transform: rotate(45deg);
  }
}
</style>
