<template>
  <div class="banner4">
    <img src="../../assets/banner/banner4.png" alt="" />
    <div id="mapPin"></div>
    <div id="funny"></div>
    <div id="funny2"></div>
    <div id="loadingMap"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  data() {
    return {
      lottieList: [
        require("../../assets/lottie/map-location-pin.json"),
        require("../../assets/lottie/funny-map-marker-pin-pointer-location-cute-owl-character (1).json"),
        require("../../assets/lottie/funny-map-marker-pin-pointer-location-cute-owl-character (1).json"),
        require("../../assets/lottie/loading-map.json"),
      ],
    };
  },
  mounted() {
    lottie.loadAnimation({
      container: document.getElementById("mapPin"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    lottie.loadAnimation({
      container: document.getElementById("funny"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
    lottie.loadAnimation({
      container: document.getElementById("funny2"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[2],
    });
    lottie.loadAnimation({
      container: document.getElementById("loadingMap"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[3],
    });
  },
};
</script>

<style lang="scss" scoped>
.banner4 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  #mapPin {
    position: absolute;
    width: 20%;
    right: 9.9%;
    bottom: 5.5%;
  }
  #funny {
    position: absolute;
    width: 10%;
    top: 21%;
    left: 12.3%;
  }
  #funny2 {
    position: absolute;
    width: 10%;
    bottom: 35%;
    left: 26.5%;
  }
  #loadingMap {
    position: absolute;
    width: 10%;
    left: 32%;
    bottom: 12%;
  }
}
</style>
