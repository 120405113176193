<template>
  <div class="banner1">
    <div class="home_banner">
      <img src="../../assets/banner/banner1.png" alt="" />
      <div class="swiper">
        <transition name="fade">
          <div
            @click="msgClick"
            v-show="phoneShowImpot && phoneShow"
            :class="phoneAlertShow ? 'phoneAlart' : 'phoneAlartRight'"
          >
            <div class="alertYes">√</div>
            <div class="alertContent">
              <div class="alertTitle">
                <p>XXXXXX位置</p>
                <p>···</p>
              </div>
              <div class="alertText">
                <p>位置：108°34′23.34″N34°12′11.42″</p>
                <p>地址：陕西省西安市雁塔区</p>
                <p>时间：2020.03.21</p>
              </div>
            </div>
          </div>
        </transition>
        <div class="swiper_logo">
          <div class="logo_content">
            <img src="../../assets/location.png" alt="" />
            <div>
              <div class="logo_title">冬天的爪哇语·摄</div>
              <div class="logo_text">E108°34′23.34″N34°12′11.42″</div>
            </div>
          </div>
        </div>
        <div class="swiper-container-h">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img :src="phoneList[0]" alt="" />
            </div>
            <div class="swiper-slide">
              <img :src="phoneList[1]" alt="" />
            </div>
            <div class="swiper-slide">
              <img :src="phoneList[2]" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      phoneList: [
        require("../../assets/phone/startUp.png"),
        require("../../assets/phone/home.png"),
        require("../../assets/phone/trajectory.png"),
      ],
      color: 3,
      phoneAlertShow: true,
      phoneShow: true,
      phoneShowImpot: true,
      mySwiper: {},
    };
  },
  mounted() {
    let that = this;
    this.mySwiper = new Swiper(".swiper-container-h", {
      slidesPerView: 2, //默认一个屏幕显示几张图
      // 设置轮播可循环
      loop: true,
      loopAdditionalSlides: 3,
      // 分页显示
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      //判断信息显示
      on: {
        slideChange: function () {
          let index = this.activeIndex;
          if (index === 2 || index === 5) {
            that.phoneShow = false;
          } else {
            that.phoneShow = true;
          }
          if (index === 3 || index === 6) {
            that.phoneAlertShow = true;
          } else if (index === 4 || index === 7) {
            that.phoneAlertShow = false;
          }
        },
      },
      slideToClickedSlide: true,
      centeredSlides: true,
    });
  },
  methods: {
    //点击信息消失
    msgClick() {
      this.phoneShowImpot = false;
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.banner1 {
  width: 100%;
  .home_banner {
    img {
      width: 100%;
      object-fit: contain;
    }
    .swiper {
      position: absolute;
      top: 3.6%;
      right: 2.3%;
      width: 44%;
      height: 92.8%;
      background: linear-gradient(179deg, #9796f0 0%, #fbc7d4 100%);
      border-radius: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .phoneAlart {
        position: absolute;
        top: 30%;
        left: 21%;
        background: #fff;
        z-index: 2;
        display: flex;
        align-items: center;
        border: 2px solid #000000;
        border-radius: 10px;
        font-size: 10px;
        overflow: auto;
        .alertYes {
          width: 20px;
          height: 20px;
          text-align: center;
          background: #ffac1c;
          border-radius: 50%;
        }
        .alertContent {
          padding: 0 10px;
          .alertTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            p {
              margin: 0;
            }
          }
          .alertText {
            color: #333;
            p {
              margin: 0;
            }
          }
        }
      }
      .phoneAlartRight {
        position: absolute;
        top: 30%;
        right: 21%;
        background: #fff;
        z-index: 2;
        display: flex;
        align-items: center;
        border: 2px solid #000000;
        border-radius: 10px;
        font-size: 10px;
        // width: 15vw;
        overflow: auto;
        .alertYes {
          width: 20px;
          height: 20px;
          text-align: center;
          background: #ffac1c;
          border-radius: 50%;
        }
        .alertContent {
          padding: 0 10px;
          .alertTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            p {
              margin: 0;
            }
          }
          .alertText {
            color: #333;
            p {
              margin: 0;
            }
          }
        }
      }
      @media screen and(max-width:1000px) {
        .phoneAlart {
          display: none;
        }
        .phoneAlartRight {
          display: none;
        }
      }
      .swiper_logo {
        width: 100%;
        margin: 20px;
        display: flex;
        justify-content: flex-end;
        .logo_content {
          width: 45%;
          // height: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          // background: rgba(255, 255, 255, 0.15);
          // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          font-size: 13px;
          font-family: Roboto, Roboto-Medium;
          font-weight: 500;
          color: #fff;
          margin-right: 3%;
          img {
            width: 56px;
            padding: 10px;
          }
          .logo_text {
            line-height: 2;
            font-size: 12px;
          }
        }
      }
      .swiper-container-h {
        width: 100%;
        margin-top: 10px;
        .swiper-slide-active {
          transform: scale(1.2);
          transition: transform 0.5s;
        }
        .swiper-pagination::v-deep {
          margin-bottom: 4%;
          .swiper-pagination-bullet {
            transform: scale(2);
            margin: 10px;
          }
          .swiper-pagination-bullet-active {
            background: #000000;
          }
        }
        .swiper-wrapper {
          .swiper-slide-prev,
          .swiper-slide-next {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
