<template>
  <div class="banner6">
    <img src="../../assets/banner/banner6.png" alt="" />
    <div id="locationTag"></div>
    <div id="locationTag2"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "banner6",
  data() {
    return {
      lottie: {},
      lottieList: [
        require("../../assets/lottie/location-tag.json"),
        require("../../assets/lottie/location-tag.json"),
      ],
    };
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("locationTag"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("locationTag2"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
  },
};
</script>

<style lang="scss" scoped>
.banner6 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  #locationTag {
    position: absolute;
    width: 6%;
    left: 13%;
    bottom: 35%;
  }
  #locationTag2 {
    position: absolute;
    width: 8%;
    left: 42%;
    bottom: 28%;
  }
}
</style>
