<template>
  <div class="banner7">
    <img src="../../assets/banner/banner7.png" alt="" />
    <div id="purple"></div>
    <div id="compass"></div>
    <div id="searching"></div>
    <div id="visualization"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
export default {
  name: "banner7",
  data() {
    return {
      lottie: {},
      lottieList: [
        require("../../assets/lottie/purple-worm.json"),
        require("../../assets/lottie/compass.json"),
        require("../../assets/lottie/searching.json"),
        require("../../assets/lottie/data-visualization.json"),
      ],
    };
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("purple"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[0],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("compass"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[1],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("searching"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[2],
    });
    this.lottie = lottie.loadAnimation({
      container: document.getElementById("visualization"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.lottieList[3],
    });
  },
};
</script>

<style lang="scss" scoped>
.banner7 {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  #purple {
    position: absolute;
    width: 10%;
    left: 31%;
    top: 24%;
  }
  #compass {
    position: absolute;
    width: 10%;
    top: 46.5%;
    right: 38.7%;
  }
  #searching {
    position: absolute;
    width: 12%;
    bottom: 17%;
    left: 19.5%;
  }
  #visualization {
    position: absolute;
    width: 12%;
    top: 23%;
    right: 22%;
  }
}
</style>
